import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';

import { MultiSelect } from "react-multi-select-component";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

const TotalsRow = ({ data }) => {
  return (
    <Tr>
      {/* <Td colSpan={2}>Totais:</Td> */}
      {data.map((item, index) => (
        // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
        index === 0 ? (
          <Td key={index} fontWeight="bold">Totais:</Td>
        ) : (
          <React.Fragment key={index}>
            <Td textAlign="center">{Object.values(item)[0]}</Td>
          </React.Fragment>
        )
      ))}
    </Tr >
  );
};

function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(valor);

  if (isNaN(data) || data.toString().includes("Invalid Date")) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();
  const horas = ("0" + data.getHours()).slice(-2);
  const minutos = ("0" + data.getMinutes()).slice(-2);

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}


function generatePDF(excel, total, filtros) {
  const doc = new jsPDF();
  let tableData = [];

  let tableFiltro = transformaFiltros([filtros]);
  let result = [];

  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }


  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 7);

  const headers_total = Object.values(total).slice(0, 7).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const values = Object.values(data[i]).slice(0, 7);
    tableData.push(values);
  }

  const columnWidths = Array(headers.length).fill(40);

  doc.setFontSize(18);
  doc.text('Visão Geral Formas De Pagamentos', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros

    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');

    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);

    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);

    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;

    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }

    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  doc.autoTable({
    head: [headers_total, headers],
    body: tableData,
    startY: tableMarginTop,
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save("relatorio_pagamentos.pdf");
}

function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Utilizadas') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === '% Do total') {
        totalizadores[key] = '100%'
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if ((key !== 'Qtd. Utilizadas') && (key !== '% Do total')) {
      value = totalizadores[key] !== '' ? `R$ ${totalizadores[key].toFixed(2)}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}


function transformaFiltros(data) {
  return data.map((item) => {
    const filtro = {};

    if (item.status) {
      filtro['Status: '] = item.status;
    }

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.categVenda) {
      filtro['Categoria da Venda: '] = item.categVenda;
    }

    if (item.origem) {
      filtro['Origem da Venda: '] = item.origem;
    }

    if (item.vendedor) {
      filtro['Vendedor: '] = item.vendedor;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};


function generateTableData(analitico) {

  if (analitico.length === 0) {
    const keys = [
      'Forma de Pagamento',
      'Valor Total',
      'Qtd. Utilizadas',
      '% Do total'
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }

  const total = analitico.reduce((acc, item) => acc + item.valorTotal, 0);

  return analitico.map((item) => ({
    'Forma de Pagamento': item.FormadePagamento,
    'Valor Total': formatarValor(item.valorTotal),
    'Qtd. Utilizadas': item.quantidade,
    '% Do total': calcularPorcentagem(total, item.valorTotal)
  }));
}


function gerarExcel(dados, objeto) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dados);

  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'relatorio_pagamentos.xlsx');
}

function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

function calcularPorcentagem(objeto, numero) {
  // Verificar se o índice existe no objeto
  // console.log(objeto)
  if (objeto['Total Valor Total']) {
    // Extrair o valor do objeto e remover o símbolo "R$" e espaços em branco
    const valorTotal = objeto['Total Valor Total'].replace(/R\$\s/g, '');

    // Converter o valor para número
    const valorNumerico = parseFloat(valorTotal);

    // Calcular a porcentagem
    const porcentagem = (numero / valorNumerico) * 100;

    // Retornar a porcentagem formatada
    return porcentagem.toFixed(2) + '%';
  } else {

    const valorNumerico = parseFloat(objeto);
    // Caso o índice não exista, retornar o valor normal do objeto
    const porcentagem = (numero / valorNumerico) * 100;

    // Retornar a porcentagem formatada
    return porcentagem.toFixed(2) + '%';
  }
}

export default function UserReports() {
  const [analitico, setAnalitico] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [status, setStatus] = useState('Pedido Faturado');
  const [empresa, setEmpresa] = useState('');
  const [origem, setOrigens] = useState('');
  const [prod, setFiltroProd] = useState('');
  const [categVenda, setCategVenda] = useState('');
  const [deposito, setDeposito] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [tabela, setTabela] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [categProd, setCategProd] = useState('');
  const [generoProd, setGeneroProd] = useState('');
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');


  const [selectedValues, setSelectedValues] = useState([]);
  const [value, setValue] = useState([])


  const options = [
    { value: 'Pedido', label: 'Pedido' },
    { value: 'Orçamento', label: 'Orçamento' },
    { value: 'Pedido Faturado', label: 'Pedido Faturado' },
    { value: 'Pedido Nao Faturado', label: 'Pedido Nao Faturado' },
    { value: 'Pedido Aprovado Sem Faturamento', label: 'Pedido Aprovado Sem Faturamento' },
  ];

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {

    const dados_formatados = generateTableData(data)
    const totais = calcularTotalizadores(dados_formatados)
    // console.log("dados formatados")
    // console.log(dados_formatados, totais)
    setExcel(dados_formatados)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = data.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {

          const [filtrosData, dadosData] = await Promise.all([
            api.get('/vendas_filters', { params: param, timeout: 10000000 })
              .catch(error => {
                throw new Error(`Erro na requisição '/vendas_filters': ${error.message}`);
              }),
            api.get('/analityc_forms', { params: param, timeout: 10000000 })
              .catch(error => {
                throw new Error(`Erro na requisição '/analityc_forms': ${error.message}`);
              })
          ]);
          // const [filtrosData, dadosData] = await Promise.all([
          //   api.get('/vendas_filters', { params: param, timeout: 10000000 }),
          //   api.get('/analityc_forms', { params: param, timeout: 10000000 }),
          // ]);

          // console.log(filtrosData)

          const filtros = filtrosData.data;
          const dados = dadosData.data;

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);

          const select_empresas = [...new Set(filtros[1].map(item => item.NomeFantasia))];
          const select_origens = [...new Set(filtros[0].map(item => item.origemVenda))];
          const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria))];


          const selectEmpresas = document.getElementById("select-empresas");
          select_empresas.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa;
            option.text = empresa;
            selectEmpresas.appendChild(option);
          });

          const selectOrigens = document.getElementById("select-origens");
          select_origens.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectOrigens.appendChild(option);
          });

          const selectCateg = document.getElementById("select-categoria-venda");
          select_categ.forEach(categVenda => {
            const option = document.createElement("option");
            option.value = categVenda;
            option.text = categVenda;
            selectCateg.appendChild(option);
          });


          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  // console.log(analitico)

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((value !== '') && value !== null) {
        data.status = value
      }


      if ((empresa !== '') && empresa !== null) {
        data.empresa = empresa
      }


      if ((origem !== '') && origem !== null) {
        data.origem = origem
      }


      if ((categVenda !== '') && categVenda !== null) {
        data.categVenda = categVenda
      }

      if ((vendedor !== '') && vendedor !== null) {
        data.vendedor = vendedor
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/analityc_forms', { params: data, timeout: 500000 })
          .then(response => {
            // console.log(response.data)

            if (response.data.length !== 0) {
              // console.log(response.data[0])
              setDados(response.data);
              setTotalItems(response.data.length);
            } else {
              setDados(response.data);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid border="1px solid black" columns={[1, 2, 3, 4]} spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}>
        <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Origem da Venda:</FormLabel>
            <Select id="select-origens" onChange={(e) => setOrigens(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda:</FormLabel>
            <MultiSelect
              options={options}
              value={value}
              onChange={setValue}
              // hasSelectAll={false}
              disableSearch={true}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos.",
                  "clearSearch": "Clear Search",
                  "clearSelected": "Clear Selected",
                  "noOptions": "No options",
                  "search": "Search",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Selecionar todos",
                  "selectSomeItems": "Selecione",
                  // "create": "Criar",
                }
              }
            />
            {/* <Select isMulti value={status} onChange={handleChange}>
              <option value="Pedido Faturado">Pedido Faturado</option>
              <option value="all">Todos</option>
              <option value="Pedido">Pedido</option>
              <option value="Orçamento">Orçamento</option>
              <option value="Pedido Nao Faturado">Pedido não Faturado</option>
              <option value="Pedido Aprovado Sem Faturamento">Pedido Aprovado Sem Faturamento</option>
            </Select> */}
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Vendedor:</FormLabel>
            <Input type="input" id="prod" onChange={(e) => setVendedor(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Categoria da Venda:</FormLabel>
            <Select id="select-categoria-venda" onChange={(e) => setCategVenda(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='5%'>


        <TableContainer w='96%' ml='2%' overflowX="auto" >
          <Table id="teste" variant="striped" w='96%' size="sm" maxWidth="100%">
            <Thead>
              {total && (
                <TotalsRow data={total} />
              )}
              <Tr>
                <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Forma de Pagamento</Th>
                <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor Total</Th>
                <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Qtd. Utilizadas</Th>
                {/* <Th textAlign="center" bg={'#3965FF'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Dt. Ultima Venda</Th> */}
                <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">% Do total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {analitico ? (
                analitico.map((item, index) =>
                (
                  <Tr key={index}>
                    <Td textAlign="center">{item.FormadePagamento}</Td>
                    <Td textAlign="center">{formatarValor(item.valorTotal)}</Td>
                    <Td textAlign="center">{item.quantidade}</Td>
                    {/* <Td textAlign="center">{formatarDataHora(item.ultimaVenda)}</Td> */}
                    <Td textAlign="center">{calcularPorcentagem(total[1], item.valorTotal)}</Td>
                  </Tr>
                )
                )
              ) : (
                <Tr>
                  <Td colSpan={2}>Não a registros!</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}
