import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { MultiSelect } from "react-multi-select-component";
import DataDisplay from './components/table';
import ModalComponent from './components/modal';


function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}


function calcularPorcentagemDiferenca(valorAntigo, valorNovo) {
  const diferenca = valorAntigo - valorNovo;
  const porcentagem = (diferenca / valorAntigo) * 100;

  // Verifica se a porcentagem possui casas decimais
  if (porcentagem % 1 !== 0) {
    return porcentagem.toFixed(2);
  } else {
    return porcentagem.toFixed(0);
  }
}


// function generatePDF(excel, total, filtros) {

//   let tableFiltro = transformaFiltros([filtros]);
//   let result = [];
  
//   for (let i = 0; i < tableFiltro.length; i++) {
//     const rowEntries = Object.entries(tableFiltro[i]);
//     const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
//       let convertedValue = value;
//       if (typeof value === 'object' && value !== null && value.props && value.props.children) {
//         convertedValue = value.props.children.toString(); // Converte o objeto em uma string
//       }
//       acc.push({ [key]: convertedValue });
//       return acc;
//     }, []);
//     result.push(filtrosConvertidos);
//   }

//   const doc = new jsPDF('landscape');
//   let tableData = [];

//   const data = excel;
//   const headers = Object.keys(data[0]).slice(0, 12);
//   const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

//   for (let i = 0; i < data.length; i++) {
//     const rowValues = Object.values(data[i]).slice(0, 12);
//     const convertedRowValues = rowValues.map((value) => {
//       if (typeof value === 'object' && value !== null && value.props && value.props.children) {
//         return value.props.children.toString(); // Converte o objeto em uma string
//       }
//       return value;
//     });
//     tableData.push(convertedRowValues);
//   }


//   doc.setFontSize(18);
//   doc.text('Relatório Pedidos de Venda', 15, 15);

//   doc.setFontSize(10);
//   doc.text('Filtros:', 15, 25);

//   const filters = result[0];
  
//   console.log(filters)// Obtém os filtros do resultado

//   if (filters) {
//     let currentX = 35; // Posição X inicial para os valores dos filtros
//     const y = 25; // Posição Y para a primeira linha dos filtros
  
//     const mergedFilters = filters.reduce((acc, filter) => {
//       const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
//       return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
//     }, '');
  
//     // Remove a vírgula extra no final
//     const mergedFiltersText = mergedFilters.slice(0, -2);
  
//     // Define o tamanho da fonte para os filtros
//     doc.setFontSize(8);
  
//     // Verifica se o texto dos filtros cabe em uma linha
//     const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
//     const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
//     let filteredText = mergedFiltersText;
  
//     if (textWidth > maxWidth) {
//       // O texto excede a largura máxima, então é necessário quebrar em várias linhas
//       const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
//       filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
//     }
  
//     // Adiciona o texto dos filtros no documento PDF
//     doc.text(filteredText, currentX, y);
//   }

//   // Define a margem superior da tabela
//   const tableMarginTop = 35;

//   // Adicionar cabeçalhos
//   doc.autoTable({
//     head: [headers_total, headers], // Adicione os cabeçalhos em ordem
//     body: tableData,
//     startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
//     columnWidth: 'auto',
//     styles: {
//       fontSize: 6,
//       cellStyles: {
//         overflow: 'linebreak', // Quebra de linha automática para células
//       },
//     },
//   });

//   // doc.save('relatorio_recebimento.pdf');
// }

function generatePDF(excel, total, filtros) {
  let tableFiltro = transformaFiltros([filtros]);
  let result = [];
  
  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;

      // Verifica se o valor é um array
      if (Array.isArray(value)) {
        // Extrai os labels dos itens do array
        convertedValue = value.map(item => item.label || "").join(", ");
      } else if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        // Converte objetos com propriedades React
        convertedValue = value.props.children.toString();
      }

      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }

  const doc = new jsPDF('landscape');
  let tableData = [];

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const rowValues = Object.values(data[i]).slice(0, 12);
    const convertedRowValues = rowValues.map((value) => {
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        return value.props.children.toString(); // Converte o objeto em uma string
      }
      return value;
    });
    tableData.push(convertedRowValues);
  }

  doc.setFontSize(18);
  doc.text('Relatório Pedidos de Venda', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0];
  
  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros
  
    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');
  
    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);
  
    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);
  
    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;
  
    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }
  
    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save('relatorio_recebimento.pdf');
}



function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Vendida') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if (key !== 'Qtd. Vendida') {
      value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};

function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(valor);
  if (isNaN(data)) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();
  const horas = ("0" + data.getHours()).slice(-2);
  const minutos = ("0" + data.getMinutes()).slice(-2);

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}


function transformaFiltros(data) {
  return data.map((item) => {
    const filtro = {};

    if (item.empresas) {
      filtro['Empresa: '] = item.empresas;
    }

    if (item.tipoData) {
      filtro['Tipo Data: '] = item.tipoData;
    }

    if (item.formas) {
      filtro['Forma Pagamento: '] = item.formas;
    }

    if (item.cliente) {
      filtro['Cliente: '] = item.cliente;
    }

    if (item.grupoPessoa) {
      filtro['Grupo do Cliente: '] = item.grupoPessoa;
    }

    if (item.centro) {
      filtro['Centro de Custo: '] = item.centro;
    }

    if (item.banco) {
      filtro['Banco: '] = item.banco;
    }

    if (item.plano) {
      filtro['Plano de Contas: '] = item.plano;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}



// function generateTableData(analitico, dataAtual) {
//   console.log(analitico)
//   if (analitico.length === 0) {
//     const keys = [
//     "Código",
//     "Categoria",
//     "Cliente",
//     "Razão Social Cliente",
//     "Empresa",
//     "Vendedor",
//     "Status",
//     "FormaPagamento",
//     "Valor Total",
//     "Custo",
//     "Lucro",
//     "Desconto",
//     "Frete",
//     "ST",
//     "IPI",
//     "FCP ST",
//     "Outras Despesas",
//     "Data Pedido",
//     "Data Aprovacao",
//     "Data Faturamento"
//     ];

//     const emptyObject = {};
//     keys.forEach(key => {
//       emptyObject[key] = '';
//     });

//     return [emptyObject];
//   }
//   // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);
//   return analitico.map((item) => ({
//     "Código": item.Codigo,
//     "Categoria": item.VendaCategoria,
//     "Cliente": item.Cliente,
//     // "Razão Social Cliente": item.RazaoSocialCliente,
//     "Empresa": item.Empresa,
//     "Vendedor": item.Vendedor,
//     "Status": item.Status,
//     "FormaPagamento": item.FormadePagamento,
//     "VT. Venda": formatarValor(item.vTotal  + item.ValorFrete  + item.vOutrasDespesas), 
//     "VT. Venda + IMP": formatarValor(item.vTotal + item.vTotalST + item.vTotalIPI + item.vFCPST_Valor + item.ValorFrete  + item.vOutrasDespesas), 
//     "Custo": formatarValor(item.CustoTotalDaVenda),
//     "Lucro": formatarValor(item.vTotal  + item.ValorFrete  + item.vOutrasDespesas - item.CustoTotalDaVenda),
//     "Desconto": formatarValor(item.vDesconto),
//     "Frete": formatarValor(item.ValorFrete),
//     "ST": formatarValor(item.vTotalST),
//     "IPI": formatarValor(item.vTotalIPI),
//     "FCP ST": formatarValor(item.vFCPST_Valor),
//     "Outras Despesas": formatarValor(item.vOutrasDespesas),
//     "Data Pedido": formatarDataHora(item.Data),
//     "Data Aprovacao": formatarDataHora(item.DataAprovacaoPedido),
//     "Data Faturamento": formatarDataHora(item.DataFaturamento),
//   }));
// }

function generateTableData(analitico) {
  if (analitico.length === 0) {
    const keys = [
      'Numero Documento',
      'Empresa',
      'Cliente/Fornecedor',
      'Valor Pago',
      'Juros Recebido',
      'Total Recebido',
      'Data de Pagamento',
      'Banco',
      'Forma de Pagamento',
      'Tipo',
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }

  return analitico.map((item) => ({
    'Numero Documento' : item.NumeroDocumento || 'N/A',
    'Empresa': item.Empresa || 'N/A',
    'Cliente/Fornecedor': item.Cliente || 'N/A',
    'Valor Pago': formatarValor(item.ValorPago || 0),
    'Juros Recebido': formatarValor(item.jurosRecebido || 0),
    'Total Recebido': formatarValor(item.jurosRecebido + item.ValorPago),
    'Data de Pagamento': item.DataPagamento
    ? formatarDataHora(item.DataPagamento)
    : 'N/A',
    'Banco': item.Banco || 'N/A',
    'Forma de Pagamento': item.FormaPagamento || 'N/A',
    'Tipo': item.Despesa ? 'Despesa' : 'Receita',
  }));
}



function gerarExcel(dados, objeto) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dados);

  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'relatorio_recebimento.xlsx');
}



function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

function calcularDiferencaDias(outraData) {
  const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Número de milissegundos em um dia
  // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);

  const data1 = new Date();
  const data2 = new Date(outraData);
  // console.log(data1, data2)

  data1.setHours(0, 0, 0, 0);
  data2.setHours(0, 0, 0, 0);

  // Calcula a diferença em milissegundos entre as datas
  const diferencaMilissegundos = Math.abs(data1 - data2);

  // Calcula a diferença em dias
  const diferencaDias = Math.round(diferencaMilissegundos / umDiaEmMilissegundos);

  return diferencaDias;
}

export default function UserReports() {
  const [analitico, setAnalitico] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [despesas, setDespesas] = useState('');
  const [formas, setFormas] = useState('');
  const [prod, setFiltroProd] = useState('');
  const [categVenda, setCategVenda] = useState('');
  const [deposito, setDeposito] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [tabela, setTabela] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [TipoData, setTipoData] = useState('DataPagamento');
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);


  const [selectEmpresas, setSelectEmpresas] = useState([]);
  const [selectFormas, setSelectFormas] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {

    const dados_formatados = await generateTableData(data, diaAtual)
    setCampos(dados_formatados)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    // console.log(dados_formatados)
    const totais = calcularTotalizadores(objFinal)
    // console.log("dados formatados")
    console.log(objFinal, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/analitico_filtros', { params: param, timeout: 100000000 }),
            api.get('/recebimentos', { params: param, timeout: 100000000 }),
          ]);

          const filtros = filtrosData.data;
          const dados = dadosData.data;

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);

          const select_empresas = filtros[0];
          const select_formas = filtros[13];

          if (select_empresas) {
            const empresa = select_empresas.map(empresa => ({
              value: empresa._id,
              label: empresa.NomeFantasia
            }));
            setEmpresas(empresa);
            // console.log(vend); // Aqui você verá os dados atualizados
          }

          if (select_formas) {
            const form = select_formas.map(frm => ({
              value: frm._id,
              label: frm.Nome
            }));
            setFormas(form);
            // console.log(vend); // Aqui você verá os dados atualizados
          }


          // const select_origens = [...new Set(filtros[1].map(item => item.origemVenda))];
          // const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria))];
          // const select_dep = [...new Set(filtros[4].map(item => item.Deposito))];
          // const select_tab = [...new Set(filtros[5].map(item => item.TabelaDePreco))];
          // const select_categ_prod = [...new Set(filtros[6].map(item => item.Categoria))];
          // const select_genero_prod = [...new Set(filtros[7].map(item => item.Genero))];

          // setDia(filtros[3]);

          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((selectEmpresas !== '') && selectEmpresas !== null) {
        data.empresas = selectEmpresas
      }

      if ((selectFormas !== '') && selectFormas !== null) {
        data.formas = selectFormas
      }

      if ((despesas !== '') && despesas !== null) {
        data.despesas = despesas
      }

      if ((TipoData !== '') && TipoData !== null) {
        data.tipoData = TipoData
      }

      if ((despesas !== '') && despesas !== null) {
        data.TipoLancamento = despesas
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/recebimentos', { params: data, timeout: 100000000 })
          .then(response => {
            // console.log(response.data)

            if (response.data.length !== 0) {
              // console.log(response.data[0])
              setDados(response.data);
              setTotalItems(response.data.length);
            } else {
              setDados(response.data);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid 
      columns={[1, 2, 3, 4]} 
      spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}
      border="1px solid black"
      >
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <MultiSelect
              options={empresas}
              value={selectEmpresas}
              onChange={setSelectEmpresas}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Formas de Pagamentos:</FormLabel>
            <MultiSelect
              options={formas}
              value={selectFormas}
              onChange={setSelectFormas}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Lançamento:</FormLabel>
            <Select id="select-despesas" onChange={(e) => setDespesas(e.target.value)}>
              <option value="">Todas</option>
              <option value="true">Despesas</option>
              <option value="false">Receitas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="DataPagamento">Data Pagamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt='1%'>
        <Button border="1px solid black" size="sm" w="10%" onClick={handleOpenModal}>Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        {/* <ModalComponent data={analitico} onValuesSelected={handleValuesSelected} isOpen={isOpen} onClose={handleCloseModal} valuesCheck={selectedValues} /> */}
        <Box mt='1%'>
          <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='2%'>
        <TableContainer w='96%' ml='2%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}
