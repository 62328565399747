import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Financeiro from "views/admin/Financeiro";
import AnaliticoProduto from "views/admin/AnaliticoProduto";
import AnaliticoForms from "views/admin/AnaliticoForms";
import AnaliticoFinance from "views/admin/AnaliticoFinance";
import Inventario from "views/admin/Inventario";
import AnaliticoPlano from "views/admin/PlanoContas";
import CaixaFinanceiro from "views/admin/FinanceiroCaixa";
import AnaliticoVenda from "views/admin/AnaliticoVendas";
import AnaliticoVendaV2 from "views/admin/AnaliticoVendaUF";
import QuadroVendas from "views/admin/QuadroVendas";
import RelatorioFormasVendas from "views/admin/RelatorioFormasVendas";
import CentroCusto from "views/admin/CentroCusto";
import AnaliticoABC from "views/admin/ProdutoABC";
import AnaliticoFormaFinan from "views/admin/AnaliticoFormaFinan";
import AnaliticoRecebimento from "views/admin/AnaliReceb";
import AnaliticoClientes from "views/admin/AnaliticoVendaCliente";
import CategoriaProdutos from "views/admin/CategoriaProduto";
import Fiscal from "views/admin/NotaFiscal";
import FiscalResumo from "views/admin/NotasResumo";
import Montagem from "views/admin/Montagem";
import users from "views/admin/users";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "teste"
  },
  {
    name: "Dashboard Vendas",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    menu: 'Vendas',
    perfil : 1,
  },
  // {
  //   name: "Dashboard Vendas teste",
  //   layout: "/admin",
  //   path: "/BI",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: testeDashboard,
  //   menu: 'Vendas',
  //   perfil : 1,
  // },
  {
    name: "Cadastro Usuarios",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: users,
    menu: 'Configuração'
  },
  // {
  //   name: "Dashboard Financeiro",
  //   layout: "/admin",
  //   path: "/financeiro",
  //   icon: (
  //      <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
  //   ),
  //   component: Financeiro,
  //   menu: 'Financeiro',
  //   perfil : 1,   
  // },
  {
    name: "Relatório Pedidos de Venda",
    layout: "/admin",
    path: "/VisaoVendas",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoVenda,
    menu: 'Vendas',
    perfil : 1, 
  },
  {
    name: "Relatório Vendas Por Estados",
    layout: "/admin",
    path: "/EstadosVendas",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoVendaV2,
    menu: 'Vendas',
    perfil : 1, 
  },
  {
    name: "Quadro Diário de Vendas",
    layout: "/admin",
    path: "/quadro",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: QuadroVendas,
    menu: 'Vendas',
    perfil : 1,  
  },
  {
    name: "Vendas por Formas de Pagamento",
    layout: "/admin",
    path: "/VendasFormas",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: RelatorioFormasVendas,
    menu: 'Vendas',
    perfil : 1,  
  },
  {
    name: "Visão de Faturamento por Cliente",
    layout: "/admin",
    path: "/FaturCliente",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoClientes,
    menu: 'Vendas',
    perfil : 1,   
  },
  {
    name: "Visão Geral dos Produtos",
    layout: "/admin",
    path: "/Produtos",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoProduto,
    menu: 'Vendas',
    perfil : 2   
  },
  {
    name: "Inventário de Produtos",
    layout: "/admin",
    path: "/Inventario",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: Inventario,
    menu: 'Vendas',
    perfil : 2   
  },
  {
    name: "Curva ABC de Produtos",
    layout: "/admin",
    path: "/CurvaAbc",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoABC,
    menu: 'Vendas' ,
    perfil : 1,  
  },
  {
    name: "Resumo por Categoria de Produtos",
    layout: "/admin",
    path: "/CategoriaProdutos",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: CategoriaProdutos,
    menu: 'Vendas',
    perfil : 1,   
  },
  {
    name: "Visão Geral Formas Pagamentos",
    layout: "/admin",
    path: "/formas",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoForms,
    menu: 'Vendas',
    perfil : 1,  
  },
  {
    name: "Visão Geral por Formas de Recebimento/Pagamento",
    layout: "/admin",
    path: "/FormasFiananceiras",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoFormaFinan,
    menu: 'Financeiro',
    perfil : 1,  
  },
  {
    name: "Analitico Recebimento/Pagamento",
    layout: "/admin",
    path: "/AnaliticoRecebimento",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoRecebimento,
    menu: 'Financeiro',
    perfil : 1,  
  },
  {
    name: "Lançamentos Financeiros",
    layout: "/admin",
    path: "/financas",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoFinance,
    menu: 'Financeiro',
    perfil : 1,  
  },
  {
    name: "Balanço de Resultado",
    layout: "/admin",
    path: "/PlanoContas",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: AnaliticoPlano,
    menu: 'Financeiro',
    perfil : 2   
  },
  {
    name: "Fechamento de Caixa Sintético",
    layout: "/admin",
    path: "/FinanceiroCaixa",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: CaixaFinanceiro,
    menu: 'Caixa',
    perfil : 2   
  },
  {
    name: "DRE Por Centro de Custo",
    layout: "/admin",
    path: "/CentroCusto",
    icon: (
       <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />
    ),
    component: CentroCusto,
    menu: 'Financeiro',
    perfil : 2 
  },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  {
    name: "Notas Fiscais Emitidas",
    layout: "/admin",
    path: "/fiscal",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Fiscal,
    menu: 'Fiscal',
    perfil : 2  
  },
  {
    name: "Notas Fiscais Resumo",
    layout: "/admin",
    path: "/resumo",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: FiscalResumo,
    menu: 'Fiscal',
    perfil : 2  
  },
  {
    name: "Login",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Montagem de Carga",
    layout: "/admin",
    path: "/Montagem",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Montagem,
    menu: 'Logistica',
    perfil : 3,  
  },
  
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
