// import React from 'react';
// import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";
// const DataDisplay = ({ data, total }) => {
//     return (
//         <Table id="teste" variant="striped" w='96%' size="sm" maxWidth="80%">
//             <Thead>
//                 <Tr>
//                     {/* <Td colSpan={2}>Totais:</Td> */}
//                     {total.map((item, index) => (
//                         // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
//                         index === 0 ? (
//                             <Td key={index} fontWeight="bold">Totais:</Td>
//                         ) : (
//                             <>
//                                 <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
//                                 {/* {index === 10 && <Td key="empty" />} Adiciona uma <Td> vazia quando index for igual a 10 */}
//                             </>
//                             // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
//                         )
//                     ))}
//                 </Tr>
//                 {data.length > 0 ? (
//                     <Tr>
//                         {Object.keys(data[0]).map((key) => (
//                             // Restante do seu código
//                             <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={key}>{key}</Th>
//                         ))}
//                     </Tr>
//                 ) : ''}
//             </Thead>
//             <Tbody>
//                 {data.map((item, index) => (
//                     <Tr key={index}>
//                         {Object.values(item).map((value, index) => (
//                             <Td textAlign="center" key={index}>{value}</Td>
//                         ))}
//                     </Tr>
//                 ))}
//             </Tbody>
//         </Table>
//         // <Box></Box>
//     );
// };

// export default DataDisplay;

import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

const DataDisplay = ({ data, total }) => {
    return (
        <Table id="teste" variant="striped" w="96%" size="sm" maxWidth="80%">
            <Thead>
                <Tr>
                    {total.map((item, index) => (
                        index === 0 ? (
                            <Td key={`total-${index}`} fontWeight="bold">Totais:</Td>
                        ) : (
                            <Td key={`total-${index}`} textAlign="center">{Object.values(item)[0]}</Td>
                        )
                    ))}
                </Tr>
                {data.length > 0 && (
                    <Tr>
                        {Object.keys(data[0]).map((key, idx) => (
                            <Th
                                key={`header-${idx}`}
                                textAlign="center"
                                bg="#5e9ff2"
                                textColor="#FFFFFF"
                                borderWidth="1px"
                                borderColor="black"
                            >
                                {key}
                            </Th>
                        ))}
                    </Tr>
                )}
            </Thead>
            <Tbody>
                {data.map((item, rowIndex) => (
                    <Tr key={`row-${rowIndex}`}>
                        {Object.values(item).map((value, colIndex) => (
                            <Td textAlign="center" key={`cell-${rowIndex}-${colIndex}`}>
                                {value}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

export default DataDisplay;
